import { useState } from 'react';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import dayjs, { Dayjs } from 'dayjs';
import { DayPicker, Modifiers, Matcher, DayPickerBase } from 'react-day-picker';
import { Language } from '@shared/types/enums';
import { isDateInPast, parseDateString } from '@utils/dateUtils';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { createTypography, mqMin } from '../../styles/base';
import { reactDayPicker } from '../../styles/reactDayPicker';
import { setNewDate } from './utils/datePickerUtils';

const StyledArrow = styled(ArrowIcon, {
  shouldForwardProp: () => false,
})<{ isRight?: boolean }>(
  ({ isRight = false, theme: { colours } }) => css`
    width: 18px;
    fill: ${colours.text.default};
    transform: rotate(${isRight ? 0 : 180}deg);
  `,
);

export const LeftArrow = () => <StyledArrow />;

export const RightArrow = () => <StyledArrow isRight />;

export interface Props {
  dateRange: DateRange;
  disabledDays?: (date: Dayjs) => boolean;
  isOneWay?: boolean;
  locale: Language;
  modifiers?: Partial<Modifiers>;
  numberOfMonths: number;
  setDateRange: (dateRange: DateRange) => void;
  weekStartsOn?: DayPickerBase['weekStartsOn'];
}

const StyledDayPicker = styled(DayPicker)(
  ({ theme: { colours, shape, spacings, typography } }) => [
    createTypography(typography.body01),
    css`
      align-self: center;
      margin: 0 0 ${spacings['16']}px;
      color: ${colours.text.default};
      line-height: 1;

      ${mqMin.Small} {
        width: 100%;
      }

      .rdp-day {
        border-radius: ${shape.borderRadiusS}px;
      }

      /* stylelint-disable selector-class-pattern */
      .rdp-months {
        position: relative;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        ${mqMin.Small} {
          flex-direction: row;
          align-items: unset;

          .rdp-caption_start {
            margin-top: unset;
          }
        }

        .rdp-month {
          margin: unset;
          margin-top: ${spacings['16']}px;

          ${mqMin.Small} {
            margin-top: unset;
          }
        }
      }

      .rdp-day:not(.rdp-day_disabled) {
        &:hover,
        &:active {
          border: none;
          background: ${colours.brand.primary};
          color: ${colours.text.on.interactive.primary.default};
          outline: none;
        }

        &:focus {
          border-color: ${colours.brand.primary};
        }
      }

      .rdp-caption_end > .rdp-caption {
        position: static;

        ${mqMin.Small} {
          position: relative;
        }
      }

      .rdp-caption_end > .rdp-caption > .rdp-nav {
        top: 30px;

        ${mqMin.Small} {
          top: 50%;
        }
      }

      /* stylelint-disable-next-line selector-not-notation */
      .rdp-day_selected:not(.rdp-day_range_start):not(.rdp-day_range_end) {
        background: ${colours.brand.primary};
        color: ${colours.text.on.interactive.primary.default};

        &:active,
        &:focus {
          outline: 1px solid ${colours.brand.primary} !important;
        }
      }

      .rdp-day_range_end,
      .rdp-day_range_start {
        background: ${colours.brand.primary} !important;
        color: ${colours.text.on.interactive.primary.default} !important;

        &:active,
        &:focus-visible {
          outline: 1px solid #000 !important;
        }
      }

      .rdp-head {
        border-top: 1px solid #000;
      }

      .rdp-caption_label {
        ${createTypography(typography.body01)};
        font-size: 20px;
      }

      .rdp-nav_button {
        &:focus,
        &:hover {
          background-color: unset;
        }
      }

      .rdp-day_disabled {
        cursor: not-allowed;

        &:hover {
          background: ${colours.surface.disabled};
          color: ${colours.text.default};
          opacity: 0.5;
        }
      }

      /* stylelint-disable-next-line selector-not-notation */
      .rdp-day_disabled:not(.rdp-day_range_start):not(.rdp-day_range_end) {
        background: ${colours.surface.disabled};
        color: ${colours.text.default};
        opacity: 0.5;

        &:focus,
        &:hover,
        &:active {
          background: ${colours.surface.disabled} !important;
          color: ${colours.text.default} !important;
          outline: 1px solid ${colours.border.strong} !important;
        }
      }

      .rdp-day_disabled.rdp-day_selected {
        opacity: 1;
      }

      /* stylelint-enable selector-class-pattern */
    `,
  ],
);

const DatePickerCalendar = ({
  dateRange,
  disabledDays,
  isOneWay = false,
  locale,
  modifiers,
  numberOfMonths,
  setDateRange,
  weekStartsOn,
}: Props) => {
  const from = dateRange.from ? dayjs(dateRange.from).toDate() : null;
  const to = dateRange.to ? dayjs(dateRange.to).toDate() : null;

  const [enteredDay, setEnteredDay] = useState(to);
  const selectedDays =
    isOneWay && from ? from : [from, { from, to: to || enteredDay }];

  return (
    <>
      <Global styles={reactDayPicker} />

      <StyledDayPicker
        components={{
          IconLeft: LeftArrow,
          IconRight: RightArrow,
        }}
        defaultMonth={from || new Date()}
        formatters={{
          formatCaption: (date: Date) =>
            // eslint-disable-next-line no-restricted-syntax
            date.toLocaleString(locale, {
              month: 'long',
              year: 'numeric',
              hour12: false,
            }),
          formatWeekdayName: (date: Date) =>
            // eslint-disable-next-line no-restricted-syntax
            date.toLocaleString(locale, {
              weekday: 'short',
              hour12: false,
            }),

          formatDay: (date: Date) =>
            // eslint-disable-next-line no-restricted-syntax
            date.toLocaleString(locale, {
              day: 'numeric',
              hour12: false,
            }),
        }}
        initialFocus
        modifiers={{
          ...modifiers,
          range_start: from as Date,
          range_end: isOneWay && from ? from : to || (enteredDay as Date),
          dis: (day: Date) => {
            const parsedDay = parseDateString(
              `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`,
            );

            return (
              isDateInPast(parsedDay) ||
              (disabledDays ? disabledDays(parsedDay) : false)
            );
          },
        }}
        modifiersClassNames={{ dis: 'rdp-day_disabled' }}
        numberOfMonths={numberOfMonths}
        onDayClick={(day, modifiers) => {
          const parsedDay = parseDateString(
            `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`,
          );

          if (
            isDateInPast(parsedDay) ||
            (disabledDays ? disabledDays(parsedDay) : false)
          ) {
            return;
          }

          setNewDate(dayjs(day), modifiers, dateRange, setDateRange, isOneWay);
        }}
        onDayMouseEnter={(day: Date) => setEnteredDay(day)}
        selected={selectedDays as Matcher | Matcher[] | undefined}
        weekStartsOn={weekStartsOn}
      />
    </>
  );
};

export default DatePickerCalendar;
